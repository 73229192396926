import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        background: "gray.200",
        fontFamily: " Inter, sans-serif"
      },
    },
  },
  colors: {
    gpPurple: {
      500: "#45399d",
      600: "#403294",
    },
    gpPink: {
      400: "#f04a86",
      500: "#ef2e70",
      600: "#dd2b6d",
    },
  }
});

export { customTheme };
